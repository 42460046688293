import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/global.css'  // This should include Tailwind CSS
import './assets/tailwind.css'

// Initialize development mode if needed
if (process.env.NODE_ENV === 'development' || process.env.VUE_APP_ENV === 'development') {
  // Initialize global storage for mock data
  window.devMockContracts = window.devMockContracts || {};
  window.devMockStorage = window.devMockStorage || {};
  window.devNextContractId = window.devNextContractId || 1;
  
  // Add dev helpers to window for easy console debugging
  window.devHelpers = {
    listContracts: () => {
      console.table(Object.values(window.devMockContracts));
      return Object.values(window.devMockContracts);
    },
    clearContracts: () => {
      window.devMockContracts = {};
      window.devNextContractId = 1;
      console.log('Development contracts cleared');
    },
    resetDevMode: () => {
      sessionStorage.removeItem('dev_auth_user');
      window.devMockContracts = {};
      window.devMockStorage = {};
      window.devNextContractId = 1;
      console.log('Development mode reset');
    }
  };
  
  console.log(
    '%cDevelopment Mode Enabled', 
    'color: white; background: green; font-size: 14px; padding: 5px; border-radius: 3px;',
    '\nYou can use these test credentials:',
    '\nEmail: testowner@notarial.online',
    '\nPassword: password123',
    '\n\nDevelopment Helpers available in console:',
    '\nwindow.devHelpers.listContracts() - List all mock contracts',
    '\nwindow.devHelpers.clearContracts() - Clear all mock contracts',
    '\nwindow.devHelpers.resetDevMode() - Reset development mode completely'
  );
}

const app = createApp(App)

app.config.globalProperties.$encodeSpecialChars = (str) => {
  if (typeof str !== 'string') return str;
  return str.replace(/[^\u0020-\u007E]/g, char => `&#${char.charCodeAt(0)};`);
}

app.mixin({
  mounted() {
    const metaCharset = document.querySelector('meta[charset]');
    if (metaCharset) {
      metaCharset.setAttribute('charset', 'utf-8');
    } else {
      const newMetaCharset = document.createElement('meta');
      newMetaCharset.setAttribute('charset', 'utf-8');
      document.head.appendChild(newMetaCharset);
    }
  }
})

app.use(router).mount('#app')