<template>
  <section id="faqs-section" class="py-24 bg-gradient-to-br from-indigo-50 to-purple-100">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="text-center max-w-3xl mx-auto mb-16">
        <h2 class="text-3xl font-bold text-gray-900">Preguntas frecuentes</h2>
        <p class="mt-4 text-lg text-gray-600">
          Todo lo que necesitas saber sobre nuestra plataforma
        </p>
      </div>

      <div class="space-y-4 max-w-3xl mx-auto">
        <div v-for="(faq, index) in faqs" :key="index"
          class="bg-white rounded-xl shadow-md hover:shadow-lg transition-shadow duration-300 p-6 border border-gray-100">
          <button class="w-full text-left group" @click="toggleFaq(index)">
            <div class="flex justify-between items-start gap-4">
              <h3 class="text-lg font-medium text-gray-900 group-hover:text-indigo-600 transition-colors">
                {{ faq.question }}
              </h3>
              <ChevronDownIcon
                class="h-6 w-6 text-gray-400 group-hover:text-indigo-600 transition-all duration-200 flex-shrink-0 mt-1"
                :class="{ 'rotate-180': faq.isOpen }" />
            </div>
            <div class="mt-4 overflow-hidden transition-all duration-200"
              :class="{ 'h-0': !faq.isOpen, 'h-auto': faq.isOpen }">
              <p class="text-gray-600 leading-relaxed">
                {{ faq.answer }}
              </p>
            </div>
          </button>
        </div>
      </div>

      <!-- Additional Support Section -->
      <div class="mt-16 text-center">
        <p class="text-gray-600 mb-4">¿No encuentras la respuesta que buscas?</p>
        <router-link to="/contacto"
          class="inline-flex items-center text-indigo-600 hover:text-indigo-700 font-medium">
          Contacta con nuestro equipo
          <ArrowRightIcon class="h-5 w-5 ml-2" />
        </router-link>
      </div>

      <div class="mt-16 text-center">
        <StartNowButton />
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref } from 'vue'
import { ChevronDownIcon, ArrowRightIcon } from '@heroicons/vue/24/solid'
import StartNowButton from './StartNowButton.vue'

const faqs = ref([
  {
    question: "¿Cuánto cuesta el servicio?",
    answer: "El registro es gratuito. La plataforma aplica una cantidad fija en concepto de tarifa de compromiso por un valor de 50€ para poder dar por completada una operación. Sin costes ocultos, sin suscripciones, sin comisiones. La tarifa de compromiso garantiza que ambas partes cumplan lo acordado.",
    isOpen: false
  },
  {
    question: "¿Cómo funciona el sistema de pujas?",
    answer: "Nuestro sistema de pujas permite que los interesados en una propiedad compitan de forma transparente. Los candidatos presentan sus ofertas, y estas se ordenan automáticamente según criterios como el precio ofertado y las condiciones propuestas. El propietario puede ver todas las ofertas organizadas, filtrando fácilmente a los candidatos más serios. Esto garantiza un proceso justo donde ganan las mejores ofertas, no los intermediarios.",
    isOpen: false
  },
  {
    question: "¿Qué ventajas ofrece notarial frente a las inmobiliarias tradicionales?",
    answer: "A diferencia de las inmobiliarias, notarial no cobra comisiones porcentuales (que suelen ser del 3-7% del valor total). Ofrecemos seguridad jurídica equivalente, sistemas de pago seguros y verificación de usuarios, pero con control total del proceso para el propietario y mayor rapidez en las transacciones, ahorrándote miles de euros en comisiones innecesarias.",
    isOpen: false
  },
  {
    question: "¿Qué ventajas tiene notarial sobre los portales inmobiliarios?",
    answer: "Los portales inmobiliarios solo conectan oferta y demanda, pero no ofrecen seguridad jurídica, verificación real de candidatos, protección de pagos ni contratos digitales verificados. Con notarial obtienes un sistema completo que va desde la publicación hasta la firma digital de contratos legalmente vinculantes, además de un filtro efectivo de candidatos serios gracias al sistema de pujas y la tarifa de compromiso.",
    isOpen: false
  },
  {
    question: "¿Por qué usar notarial en lugar del trato directo entre particulares?",
    answer: "El trato directo entre particulares carece de seguridad jurídica, verificación de identidad, protección de pagos y filtrado de candidatos serios. Notarial te ofrece todas estas ventajas manteniendo el control total del proceso y sin comisiones porcentuales, proporcionando la tranquilidad y seguridad que el trato directo no puede garantizar.",
    isOpen: false
  },
  {
    question: "¿Cómo verifican a los inquilinos y propietarios?",
    answer: "Realizamos verificaciones automáticas de identidad y documentos para garantizar la seguridad. Utilizamos tecnología avanzada para validar la autenticidad de los documentos subidos y verificar la identidad en tiempo real.",
    isOpen: false
  },
  {
    question: "¿Qué documentos necesito para empezar?",
    answer: "Tanto si eres el propietario de un inmueble como si estás buscando el tuyo, tan solo necesitas tener preparado un documento que verifique tu identidad para poder garantizar la seguridad de todas las partes. Según el tipo de perfil que tengas, te guiaremos paso a paso en el proceso con el resto de documentos que necesites.",
    isOpen: false
  },
  {
    question: "¿Son seguros mis datos personales y bancarios?",
    answer: "Absolutamente. Cumplimos con la normativa RGPD y utilizamos encriptación para proteger todos tus datos. Nunca compartimos tu información con terceros sin tu consentimiento explícito.",
    isOpen: false
  },
  {
    question: "¿Es segura la firma digital?",
    answer: "Sí, utilizamos un sistema de firma digital que cumple y garantiza la máxima seguridad. Nuestras firmas tienen la misma validez legal que las manuscritas.",
    isOpen: false
  },
  {
    question: "¿Qué garantías tengo al usar la plataforma?",
    answer: "Ofrecemos verificación de identidad, contratos legales, sistema seguro de pagos y soporte especializado durante todo el proceso para casos individuales. Además, la tarifa de compromiso protege a ambas partes contra cancelaciones injustificadas.",
    isOpen: false
  },
  {
    question: "¿Qué soporte ofrecen durante el contrato?",
    answer: "Contamos con un equipo especializado disponible para resolver cualquier incidencia contemplada en nuestro contrato.",
    isOpen: false
  },
  {
    question: "¿Cómo funciona el sistema de pagos?",
    answer: "Utilizamos pasarelas de pago seguras como Stripe que cumplen con los estándares PCI DSS. Los pagos se procesan de forma encriptada y nunca almacenamos los datos completos de tu tarjeta en nuestros servidores.",
    isOpen: false
  },
  {
    question: "¿Qué validez legal tienen los contratos generados?",
    answer: "Nuestros contratos son redactados y adaptados dinámicamente a la normativa vigente. Tienen plena validez legal y son admitidos en cualquier juzgado o procedimiento oficial.",
    isOpen: false
  },
  {
    question: "¿Qué ocurre si hay un conflicto entre las partes?",
    answer: "En caso de desacuerdo previo al pago de la tarifa de compromiso por ambas partes, el sistema de tarifa de compromiso actúa como mecanismo de protección, la parte perjudicada puede solicitar el reembolso de la tarifa de compromiso y el proceso se dará por cerrado.",
    isOpen: false
  }
])

function toggleFaq(index) {
  faqs.value = faqs.value.map((faq, i) => ({
    ...faq,
    isOpen: i === index ? !faq.isOpen : false
  }))
}
</script> 