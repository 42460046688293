<template>
  <div class="min-h-screen bg-custom-bg flex flex-col">
    <!-- Navigation wrapper - Fixed position with !important for precedence -->
    <header class="w-full fixed top-0 left-0 z-[100] bg-white shadow-md">
      <nav class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="flex justify-between h-16">
          <div class="flex">
            <div class="flex-shrink-0 flex items-center">
              <!-- You can add your logo here -->
              <router-link :to="isAuthenticated ? '/contracts' : '/'" class="text-xl font-bold text-indigo-600">notarial</router-link>
            </div>
            <div class="hidden sm:ml-6 sm:flex sm:space-x-8">
              <router-link v-if="isAuthenticated" to="/contracts" :class="[
                $route.path.startsWith('/contracts')
                  ? 'border-indigo-500 text-gray-900'
                  : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                'inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium'
              ]">
                Contratos
              </router-link>
              
              <a v-if="!isAuthenticated" 
                 @click.prevent="scrollToComparison" 
                 href="#" 
                 class="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium cursor-pointer">
                Por qué elegirnos
              </a>
              
              <a v-if="!isAuthenticated" 
                 @click.prevent="scrollToHowItWorks" 
                 href="#" 
                 class="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium cursor-pointer">
                Cómo funciona
              </a>

              <a v-if="!isAuthenticated" 
                 @click.prevent="scrollToFaqs" 
                 href="#" 
                 class="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium cursor-pointer">
                Preguntas frecuentes
              </a>
              
              <router-link to="/blog" :class="[
                $route.path.startsWith('/blog')
                  ? 'border-indigo-500 text-gray-900'
                  : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                'inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium'
              ]">
                Blog
              </router-link>
            </div>
          </div>
          <div class="hidden sm:ml-6 sm:flex sm:items-center">
            <!-- Dynamic login/logout button -->
            <div v-if="!isAuthenticated" class="flex space-x-3">
              <router-link to="/signup"
                class="bg-white text-indigo-600 border-2 border-transparent px-4 py-2 rounded-md text-sm font-medium transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 text-center shadow-sm">
                Empieza ahora
              </router-link>
              <button @click="navigateToLogin"
                class="bg-gradient-to-r from-purple-600 to-indigo-600 text-white px-4 py-2 rounded-md text-sm font-medium hover:from-purple-700 hover:to-indigo-700 transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                Iniciar sesión  
              </button>
              
            </div>
            <div v-else class="relative dropdown-container">
              <button @click="toggleUserMenu"
                class="flex items-center space-x-2 text-gray-700 hover:text-gray-900 focus:outline-none">
                <span class="text-sm font-medium">{{ user?.displayName || user?.email }}</span>
                <svg class="h-5 w-5" :class="{ 'transform rotate-180': isUserMenuOpen }"
                  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clip-rule="evenodd" />
                </svg>
              </button>

              <!-- Dropdown menu -->
              <div v-if="isUserMenuOpen"
                class="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100">
                <div class="py-1">
                 
                  <router-link to="/profile" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    @click="isUserMenuOpen = false">
                    Ver perfil
                  </router-link>
                  <router-link v-if="user?.email === 'soporte@notarial.online'" to="/admin-panel" 
                    class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    @click="isUserMenuOpen = false">
                    Panel de Administración
                  </router-link>
                </div>
                <div class="py-1">
                  <button @click="logout"
                    class="block w-full text-left px-4 py-2 text-sm text-red-600 hover:bg-gray-100">
                    Salir
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="-mr-2 flex items-center sm:hidden">
            <!-- Mobile menu button -->
            <button @click="toggleMobileMenu" type="button"
              class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
              aria-controls="mobile-menu" aria-expanded="false">
              <span class="sr-only">Open main menu</span>
              <!-- Icon when menu is closed -->
              <svg :class="{ 'hidden': mobileMenuOpen, 'block': !mobileMenuOpen }" class="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
              </svg>
              <!-- Icon when menu is open -->
              <svg :class="{ 'block': mobileMenuOpen, 'hidden': !mobileMenuOpen }" class="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </div>
      </nav>

      <!-- Mobile menu -->
      <div v-show="mobileMenuOpen" class="sm:hidden fixed top-16 left-0 w-full bg-white shadow-lg z-40"
        id="mobile-menu">
        <div class="pt-2 pb-3 space-y-1">
          <router-link v-if="isAuthenticated" to="/contracts" :class="[
            $route.path.startsWith('/contracts')
              ? 'bg-indigo-50 border-indigo-500 text-indigo-700'
              : 'border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700',
            'block pl-3 pr-4 py-2 border-l-4 text-base font-medium'
          ]" @click="mobileMenuOpen = false">
            Contratos
          </router-link>
          
          <a v-if="!isAuthenticated" 
             @click="scrollToComparisonMobile" 
             href="#" 
             class="border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium cursor-pointer">
            Por qué elegirnos
          </a>
          
          <a v-if="!isAuthenticated" 
             @click="scrollToHowItWorksMobile" 
             href="#" 
             class="border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium cursor-pointer">
            Cómo funciona
          </a>

          <a v-if="!isAuthenticated" 
             @click="scrollToFaqsMobile" 
             href="#" 
             class="border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium cursor-pointer">
            Preguntas frecuentes
          </a>
          
          <router-link to="/blog" :class="[
            $route.path.startsWith('/blog')
              ? 'bg-indigo-50 border-indigo-500 text-indigo-700'
              : 'border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700',
            'block pl-3 pr-4 py-2 border-l-4 text-base font-medium'
          ]" @click="mobileMenuOpen = false">
            Blog
          </router-link>
          
          <router-link v-if="isAuthenticated" to="/profile" :class="[
            $route.path === '/profile'
              ? 'bg-indigo-50 border-indigo-500 text-indigo-700'
              : 'border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700',
            'block pl-3 pr-4 py-2 border-l-4 text-base font-medium'
          ]" @click="mobileMenuOpen = false">
            Perfil
          </router-link>
          <router-link v-if="isAuthenticated && user?.email === 'soporte@notarial.online'" to="/admin-panel" :class="[
            $route.path === '/admin-panel'
              ? 'bg-indigo-50 border-indigo-500 text-indigo-700'
              : 'border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700',
            'block pl-3 pr-4 py-2 border-l-4 text-base font-medium'
          ]" @click="mobileMenuOpen = false">
            Panel de Administración
          </router-link>
        </div>
        <div class="pt-4 pb-3 border-t border-gray-200">
          <div v-if="isAuthenticated" class="space-y-1">
            <button @click="logout"
              class="block w-full text-left px-4 py-2 text-base font-medium text-red-600 hover:bg-gray-100">
              Salir
            </button>
          </div>
          <div v-else class="px-4 py-2 space-y-3">
            <router-link
              to="/signup"
              class="block w-full bg-white text-indigo-600 border-2 border-transparent px-4 py-2 rounded-md text-sm font-medium transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 text-center shadow-sm">
              Empieza ahora
            </router-link>
            <button
              @click="navigateToLogin"
              class="w-full bg-gradient-to-r from-purple-600 to-indigo-600 text-white px-4 py-2 rounded-md text-sm font-medium hover:from-purple-700 hover:to-indigo-700 transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Iniciar sesión
            </button>
            
          </div>
        </div>
      </div>
    </header>

    <!-- Main content - Added padding-top to account for fixed navbar -->
    <div :class="[
      mainContentClass,
      'flex-grow pt-20' // Increased padding-top to ensure no overlap
    ]">
      <router-view></router-view>
    </div>

    <!-- Footer wrapper -->
    <div class="bg-gray-800 w-full mt-auto" :class="{ 'hidden': isMobile && !mobileMenuOpen }">
      <footer class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-white py-4">
        <div class="flex justify-center items-center space-x-4 text-sm">
          <p>© 2025 notarial</p>
          <span class="text-gray-500">|</span>
          <router-link to="/privacy-policy" class="hover:text-gray-300 transition-colors">
            Política de privacidad
          </router-link>
          <span class="text-gray-500">|</span>
          <router-link to="/contacto" class="hover:text-gray-300 transition-colors">
            Contacto
          </router-link>
        </div>
      </footer>
    </div>
    <CookiesBanner @accept="handleCookiesAccept" @reject="handleCookiesReject" />
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth'
import { doc, getDoc } from 'firebase/firestore'
import { db } from './firebase'
import CookiesBanner from './components/CookiesBanner.vue'

export default {
  name: 'App',
  components: {
    CookiesBanner
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const isAuthenticated = ref(false)
    const mobileMenuOpen = ref(false)
    const isUserMenuOpen = ref(false)
    const user = ref(null)
    const hasCompletedProfile = ref(false)
    const isProfileCheckComplete = ref(false)
    const isMobile = ref(false)

    const mainContentClass = computed(() => {
      const isViewContractPublic = route.name === 'ViewContractPublic'
      return {
        'container mx-auto px-4 py-8': true,
        'lg:max-w-4xl xl:max-w-5xl': isViewContractPublic
      }
    })

    const toggleMobileMenu = () => {
      mobileMenuOpen.value = !mobileMenuOpen.value
    }

    const navigateToLogin = () => {
      router.push('/login')
      mobileMenuOpen.value = false
    }
    
    const scrollToComparison = () => {
      if (route.path !== '/') {
        router.push('/').then(() => {
          setTimeout(() => {
            const comparisonTable = document.getElementById('comparison-table')
            if (comparisonTable) {
              comparisonTable.scrollIntoView({ behavior: 'smooth', block: 'start' })
              // Ajustar por el header fijo
              const headerOffset = 80
              const elementPosition = comparisonTable.getBoundingClientRect().top
              const offsetPosition = elementPosition - headerOffset
              window.scrollBy({
                top: offsetPosition,
                behavior: 'smooth'
              })
            }
          }, 500)
        })
      } else {
        const comparisonTable = document.getElementById('comparison-table')
        if (comparisonTable) {
          comparisonTable.scrollIntoView({ behavior: 'smooth', block: 'start' })
          // Ajustar por el header fijo
          const headerOffset = 80
          const elementPosition = comparisonTable.getBoundingClientRect().top
          const offsetPosition = elementPosition - headerOffset
          window.scrollBy({
            top: offsetPosition,
            behavior: 'smooth'
          })
        }
      }
    }
    
    const scrollToComparisonMobile = () => {
      mobileMenuOpen.value = false // Cerrar el menú móvil primero
      setTimeout(() => { // Dar tiempo a que se cierre el menú
        scrollToComparison()
      }, 100)
    }

    const scrollToHowItWorks = () => {
      if (route.path !== '/') {
        router.push('/').then(() => {
          setTimeout(() => {
            const howItWorksSection = document.getElementById('step-by-step-guide')
            if (howItWorksSection) {
              howItWorksSection.scrollIntoView({ behavior: 'smooth', block: 'start' })
              const headerOffset = 80
              const elementPosition = howItWorksSection.getBoundingClientRect().top
              const offsetPosition = elementPosition - headerOffset
              window.scrollBy({
                top: offsetPosition,
                behavior: 'smooth'
              })
            }
          }, 500)
        })
      } else {
        const howItWorksSection = document.getElementById('step-by-step-guide')
        if (howItWorksSection) {
          howItWorksSection.scrollIntoView({ behavior: 'smooth', block: 'start' })
          const headerOffset = 80
          const elementPosition = howItWorksSection.getBoundingClientRect().top
          const offsetPosition = elementPosition - headerOffset
          window.scrollBy({
            top: offsetPosition,
            behavior: 'smooth'
          })
        }
      }
    }
    
    const scrollToHowItWorksMobile = () => {
      mobileMenuOpen.value = false
      setTimeout(() => {
        scrollToHowItWorks()
      }, 100)
    }

    const scrollToFaqs = () => {
      if (route.path !== '/') {
        router.push('/').then(() => {
          setTimeout(() => {
            const faqsSection = document.getElementById('faqs-section')
            if (faqsSection) {
              faqsSection.scrollIntoView({ behavior: 'smooth', block: 'start' })
              const headerOffset = 80
              const elementPosition = faqsSection.getBoundingClientRect().top
              const offsetPosition = elementPosition - headerOffset
              window.scrollBy({
                top: offsetPosition,
                behavior: 'smooth'
              })
            }
          }, 500)
        })
      } else {
        const faqsSection = document.getElementById('faqs-section')
        if (faqsSection) {
          faqsSection.scrollIntoView({ behavior: 'smooth', block: 'start' })
          const headerOffset = 80
          const elementPosition = faqsSection.getBoundingClientRect().top
          const offsetPosition = elementPosition - headerOffset
          window.scrollBy({
            top: offsetPosition,
            behavior: 'smooth'
          })
        }
      }
    }
    
    const scrollToFaqsMobile = () => {
      mobileMenuOpen.value = false
      setTimeout(() => {
        scrollToFaqs()
      }, 100)
    }

    const logout = async () => {
      // Manejo de cierre de sesión simulado en desarrollo
      if (process.env.VUE_APP_ENABLE_SIGNUP_SIMULATION === 'true') {
        localStorage.removeItem('devUser')
        isAuthenticated.value = false
        user.value = null
        router.push('/')
        mobileMenuOpen.value = false
        return
      }

      const auth = getAuth()
      try {
        await signOut(auth)
        router.push('/')
        mobileMenuOpen.value = false
      } catch (error) {
        console.error('Error signing out:', error)
      }
    }

    const handleCookiesAccept = () => {
      // You can implement additional cookie acceptance logic here
      console.log('Cookies accepted')
    }

    const handleCookiesReject = () => {
      // You can implement additional cookie rejection logic here
      console.log('Cookies rejected')
    }

    const toggleUserMenu = () => {
      isUserMenuOpen.value = !isUserMenuOpen.value
    }

    const checkProfileCompletion = async () => {
      const auth = getAuth()
      const user = auth.currentUser
      if (user) {
        const userDoc = await getDoc(doc(db, 'users', user.uid))
        if (userDoc.exists()) {
          const userData = userDoc.data()
          hasCompletedProfile.value = !!(
            userData.dni && 
            userData.dniFrontPictureUrl && 
            userData.dniBackPictureUrl && 
            (userData.userType !== 'owner' || userData.bankNumber)
          )
        }
      }
      isProfileCheckComplete.value = true
    }

    const updateIsMobile = () => {
      isMobile.value = window.innerWidth < 640
    }

    onMounted(() => {
      updateIsMobile()
      window.addEventListener('resize', updateIsMobile)
      
      document.addEventListener('click', (event) => {
        const userMenu = document.querySelector('.relative')
        if (userMenu && !userMenu.contains(event.target)) {
          isUserMenuOpen.value = false
        }
      })

      // Manejo de usuario simulado en desarrollo
      if (process.env.VUE_APP_ENABLE_SIGNUP_SIMULATION === 'true') {
        const devUser = localStorage.getItem('devUser')
        if (devUser) {
          const parsedUser = JSON.parse(devUser)
          isAuthenticated.value = true
          user.value = parsedUser
          checkProfileCompletion()
          return
        }
      }

      const auth = getAuth()
      onAuthStateChanged(auth, (currentUser) => {
        isAuthenticated.value = !!currentUser
        user.value = currentUser
        if (currentUser) {
          checkProfileCompletion()
        }
      })
    })

    return {
      mainContentClass,
      isAuthenticated,
      mobileMenuOpen,
      isUserMenuOpen,
      user,
      isMobile,
      toggleUserMenu,
      toggleMobileMenu,
      navigateToLogin,
      logout,
      handleCookiesAccept,
      handleCookiesReject,
      hasCompletedProfile,
      isProfileCheckComplete,
      scrollToComparison,
      scrollToComparisonMobile,
      scrollToHowItWorks,
      scrollToHowItWorksMobile,
      scrollToFaqs,
      scrollToFaqsMobile
    }
  }
}
</script>

<style>
/* Update z-index hierarchy */
.bg-white.shadow-sm {
  z-index: 50;
  /* Add box shadow for better visual separation */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  /* Add transition for smooth scroll effect */
  transition: transform 0.3s ease;
}

/* Removed redundant mobile menu styles */

nav {
  position: relative;
}

.dropdown-container {
  position: relative;
  isolation: isolate;
}

.dropdown-container .absolute {
  z-index: 60;
}

/* Important fixes for sticky header */
header.fixed {
  position: fixed !important;
  width: 100% !important;
}

/* Para asegurar que la sección tenga margen superior al hacer scroll */
#comparison-table {
  scroll-margin-top: 80px;
}
</style>