<template>
  <section id="call-section" class="py-24 bg-white">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="grid md:grid-cols-2 gap-12 items-center">
        <div class="space-y-8">
          <div>
            <h2 class="text-3xl font-bold text-gray-900 mb-4">¿Prefieres que te <span
                class="text-indigo-600">llamemos</span>?</h2>
            <p class="text-xl text-gray-700">
              Déjanos tu número y te contactaremos para resolver todas tus dudas sobre el proceso
            </p>
          </div>

          <div class="space-y-6">
            <div class="flex items-start gap-4">
              <div class="rounded-full bg-green-100 p-3 flex-shrink-0">
                <PhoneIcon class="h-6 w-6 text-green-600" />
              </div>
              <div>
                <h3 class="text-xl font-medium text-gray-900">Atención personalizada</h3>
                <p class="mt-2 text-gray-600">Te ofrecemos asesoramiento personalizado para resolver todas tus dudas.
                </p>
              </div>
            </div>

            <div class="flex items-start gap-4">
              <div class="rounded-full bg-purple-100 p-3 flex-shrink-0">
                <ClockIcon class="h-6 w-6 text-purple-600" />
              </div>
              <div>
                <h3 class="text-xl font-medium text-gray-900">Respuesta rápida</h3>
                <p class="mt-2 text-gray-600">Te contactaremos lo antes posible para ayudarte con tu caso específico.
                </p>
              </div>
            </div>
          </div>

          <div class="bg-indigo-50 rounded-lg p-6 shadow-md border border-indigo-100">
            <p class="text-lg text-gray-800 italic">
              "El equipo resolvió todas mis dudas por teléfono en minutos. Me ahorraron mucho tiempo y confusión."
            </p>
            <p class="mt-4 font-medium text-gray-900">— Antonio R., Propietario</p>
          </div>
        </div>

        <div class="bg-white rounded-xl shadow-xl p-8 border border-gray-200">
          <form @submit.prevent="handleCallRequest" class="space-y-6">
            <div>
              <label for="phone" class="block text-sm font-medium text-gray-700 mb-2">
                Número de teléfono
              </label>
              <div class="relative">
                <input id="phone" v-model="phoneNumber" type="tel" required pattern="[0-9]{9}"
                  class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 py-3"
                  placeholder="Ej: 666777888" />
              </div>
            </div>

            <div class="flex items-start">
              <div class="flex items-center h-5">
                <input id="privacy" v-model="acceptedPrivacy" type="checkbox" required
                  class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500" />
              </div>
              <div class="ml-3">
                <label for="privacy" class="text-sm text-gray-600">
                  Acepto la <router-link to="/privacy-policy" class="text-indigo-600 hover:text-indigo-700">política
                    de privacidad</router-link> y el tratamiento de mis datos
                </label>
              </div>
            </div>

            <button type="submit" :disabled="isSubmitting"
              class="w-full flex justify-center items-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gradient-to-r from-purple-600 to-indigo-600 hover:from-purple-700 hover:to-indigo-700 transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50 disabled:cursor-not-allowed shadow-sm">
              <span v-if="isSubmitting">
                <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" fill="none" viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                  <path class="opacity-75" fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                  </path>
                </svg>
                Enviando...
              </span>
              <span v-else>Solicitar llamada</span>
            </button>

            <Transition enter-active-class="transition ease-out duration-200"
              enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100"
              leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100"
              leave-to-class="transform opacity-0 scale-95">
              <div v-if="showSuccess" class="mt-4 p-4 bg-green-50 rounded-md border border-green-200">
                <div class="flex">
                  <CheckCircleIcon class="h-5 w-5 text-green-500" />
                  <div class="ml-3">
                    <p class="text-sm font-medium text-green-800">
                      Solicitud recibida. Te llamaremos lo antes posible.
                    </p>
                  </div>
                </div>
              </div>
            </Transition>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref } from 'vue'
import { CheckCircleIcon, PhoneIcon, ClockIcon } from '@heroicons/vue/24/solid'
import { collection, addDoc } from 'firebase/firestore'
import { db } from '@/firebase'
import axios from 'axios'

const phoneNumber = ref('')
const acceptedPrivacy = ref(false)
const isSubmitting = ref(false)
const showSuccess = ref(false)

async function handleCallRequest() {
  if (!phoneNumber.value || !acceptedPrivacy.value) return

  isSubmitting.value = true

  try {
    // Guardar en Firestore
    await addDoc(collection(db, 'callRequests'), {
      phoneNumber: phoneNumber.value,
      createdAt: new Date(),
      status: 'pending'
    })

    // Enviar email de notificación
    try {
      await axios.post('/api/email/send-call-request', {
        phoneNumber: phoneNumber.value
      });
      console.log('Notificación de solicitud de llamada enviada');
    } catch (emailError) {
      console.error('Error al enviar notificación de llamada:', emailError);
      // Continuar incluso si falla el envío de correo
    }

    phoneNumber.value = ''
    acceptedPrivacy.value = false
    showSuccess.value = true

    // Hide success message after 5 seconds
    setTimeout(() => {
      showSuccess.value = false
    }, 5000)
  } catch (error) {
    console.error('Error submitting call request:', error)
  } finally {
    isSubmitting.value = false
  }
}
</script> 