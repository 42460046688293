import { createRouter, createWebHistory } from 'vue-router'
import { auth } from './firebase'
import { onAuthStateChanged } from 'firebase/auth'
import LandingPage from './components/LandingPage.vue'

const routes = [
  { 
    path: '/', 
    name: 'Landing',
    component: LandingPage,
    meta: { 
      title: 'Inicio',
      requiresAuth: false 
    }
  },
  { 
    path: '/contracts/:id', 
    component: () => import('./components/ViewContractPublic.vue'),
    meta: { requiresAuth: false, allowInvited: true } 
  },
  { 
    path: '/dashboard', 
    component: () => import('./views/Dashboard.vue'),
    meta: { requiresAuth: true },
    children: [
      { path: '', redirect: '/contracts' },
      { 
        path: 'profile', 
        component: () => import('./components/Profile.vue')
      },
    ]
  },
  {
    path: '/contracts',
    component: () => import('./components/Contracts.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/contracts/add',
    component: () => import('./components/AddContract.vue'),
    meta: { requiresAuth: true, requiresCompleteProfile: true }
  },
  {
    path: '/contracts/edit/:id',
    name: 'EditContract',
    component: () => import('./components/EditContract.vue'),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/contracts/:id',
    name: 'ViewContractPublic',
    component: () => import('./components/ViewContractPublic.vue'),
    props: true,
    meta: { requiresAuth: false, allowInvited: true }
  },
  { 
    path: '/signup', 
    component: () => import('./components/SignUp.vue')
  },
  { 
    path: '/login', 
    component: () => import('./components/Login.vue')
  },
  { 
    path: '/forgot-password', 
    component: () => import('./components/ForgotPassword.vue')
  },
  { 
    path: '/profile', 
    component: () => import('./components/Profile.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/complete-profile',
    component: () => import('./components/CompleteProfile.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () => import('./views/PrivacyPolicy.vue'),
    meta: {
      title: 'Política de Privacidad'
    }
  },
  {
    path: '/contacto',
    name: 'contacto',
    component: () => import('./components/Contacto.vue'),
    meta: {
      title: 'Contacto'
    }
  },
  {
    path: '/admin-panel',
    name: 'AdminPanel',
    component: () => import('./components/AdminPanel.vue'),
    meta: { 
      requiresAuth: true,
      requiresSuperuser: true,
      title: 'Panel de Administración'
    }
  },
  // Rutas del Blog
  {
    path: '/blog',
    name: 'BlogPage',
    component: () => import('./components/BlogPage.vue'),
    meta: { 
      requiresAuth: false,
      title: 'Blog'
    }
  },
  // Rutas de administración del blog
  {
    path: '/admin-panel/blog',
    name: 'BlogPostList',
    component: () => import('./components/BlogPostList.vue'),
    meta: { 
      requiresAuth: true,
      requiresSuperuser: true,
      title: 'Administrar Blog'
    }
  },
  {
    path: '/admin-panel/blog/new',
    name: 'NewBlogPost',
    component: () => import('./components/BlogPostEditor.vue'),
    meta: { 
      requiresAuth: true,
      requiresSuperuser: true,
      title: 'Nuevo Artículo'
    }
  },
  {
    path: '/admin-panel/blog/edit/:id',
    name: 'EditBlogPost',
    component: () => import('./components/BlogPostEditor.vue'),
    props: true,
    meta: { 
      requiresAuth: true,
      requiresSuperuser: true,
      title: 'Editar Artículo'
    }
  },
  // Esta ruta debe estar al final para capturar posibles posts de blog
  {
    path: '/:slug',
    name: 'BlogPostView',
    component: () => import('./components/BlogPostView.vue'),
    props: true,
    meta: { 
      requiresAuth: false,
      title: 'Artículo'
    }
  },
  // 404 route - must be last
  {
    path: '/:pathMatch(.*)*',
    component: () => import('./components/NotFound.vue'),
    name: 'NotFound'
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    // Siempre volver al inicio de la página al cambiar de ruta
    return { top: 0 }
  }
});

let isAuthReady = false
let initialAuthCheck = new Promise((resolve) => {
  onAuthStateChanged(auth, (user) => {
    isAuthReady = true
    resolve(user)
  })
})

router.beforeEach(async (to, from, next) => {
  if (!isAuthReady) {
    await initialAuthCheck
  }

  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const requiresCompleteProfile = to.matched.some(record => record.meta.requiresCompleteProfile);
  const requiresSuperuser = to.matched.some(record => record.meta.requiresSuperuser);
  const allowInvited = to.matched.some(record => record.meta.allowInvited);
  const isAuthenticated = auth.currentUser;
  
  // Check for development mode auth
  const isDevelopment = process.env.NODE_ENV === 'development' || process.env.VUE_APP_ENV === 'development';
  const devAuthUser = isDevelopment ? JSON.parse(sessionStorage.getItem('dev_auth_user') || 'null') : null;
  
  if (requiresAuth && !isAuthenticated) {
    // If we're in development mode and have a dev auth user, allow access
    if (isDevelopment && devAuthUser) {
      console.log('Usando autenticación de desarrollo para acceder a ruta protegida');
      next();
    } else if (allowInvited && to.name === 'ViewContractPublic') {
      // For invited users, allow access to ViewContractPublic but store the path for post-login redirect
      next({ path: '/login', query: { redirect: to.fullPath } });
    } else {
      next('/login');
    }
  } else if (requiresSuperuser && isAuthenticated) {
    // Verificar si el usuario es superusuario
    if (auth.currentUser.email === 'soporte@notarial.online') {
      next();
    } else {
      // Redirigir a la página principal si no es superusuario
      next('/');
    }
  } else if (requiresCompleteProfile && isAuthenticated) {
    // Check if the user has completed their profile
    try {
      const { doc, getDoc } = await import('firebase/firestore');
      const { db } = await import('./firebase');
      
      const userDoc = await getDoc(doc(db, 'users', auth.currentUser.uid));
      
      if (userDoc.exists()) {
        const userData = userDoc.data();
        const hasCompletedProfile = !!(
          userData.dni && 
          userData.dniFrontPictureUrl && 
          userData.dniBackPictureUrl && 
          (userData.userType !== 'owner' || userData.bankNumber)
        );
        
        if (!hasCompletedProfile) {
          next('/complete-profile');
        } else {
          next();
        }
      } else {
        next('/complete-profile');
      }
    } catch (error) {
      console.error('Error checking profile completion:', error);
      next('/complete-profile');
    }
  } else {
    next();
  }
});

export default router