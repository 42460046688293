<template>
  <section class="py-24 bg-gradient-to-br from-slate-50 to-white relative overflow-hidden">
    <!-- Decorative blur effects -->
    <div class="absolute inset-0 opacity-30">
      <div class="absolute top-0 left-1/4 w-64 h-64 bg-indigo-200/30 rounded-full filter blur-3xl"></div>
      <div class="absolute bottom-0 right-1/4 w-64 h-64 bg-purple-200/30 rounded-full filter blur-3xl"></div>
    </div>

    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="text-center max-w-3xl mx-auto mb-16">
        <h2 class="text-3xl font-bold text-gray-900">¿Cómo funciona?</h2>
        <p class="mt-4 text-lg text-gray-600">
          Descubre paso a paso cómo utilizar nuestra plataforma según tu perfil
        </p>
      </div>

      <!-- User Type Selector -->
      <div class="flex justify-center mb-12 relative z-10">
        <div class="inline-flex rounded-lg p-1 bg-white shadow-sm space-x-2">
          <button 
            @click="activeGuideType = 'owner'" 
            :class="[
              'px-6 py-2 text-sm font-medium rounded-md transition-all duration-200',
              activeGuideType === 'owner'
                ? 'bg-gradient-to-r from-purple-600 to-indigo-600 text-white shadow-sm'
                : 'text-gray-600 hover:text-gray-900 hover:bg-gray-50'
            ]"
          >
            Soy propietario
          </button>
          <button 
            @click="activeGuideType = 'applicant'" 
            :class="[
              'px-6 py-2 text-sm font-medium rounded-md transition-all duration-200',
              activeGuideType === 'applicant'
                ? 'bg-gradient-to-r from-purple-600 to-indigo-600 text-white shadow-sm'
                : 'text-gray-600 hover:text-gray-900 hover:bg-gray-50'
            ]"
          >
            Busco propiedad
          </button>
        </div>
      </div>

      <!-- Owner Steps -->
      <div v-show="activeGuideType === 'owner'" class="relative">
        <!-- Carousel Container -->
        <div class="overflow-hidden">
          <div class="relative w-full" style="min-height: 420px">
            <!-- Step 1: Create Contract -->
            <div class="w-full transition-all duration-300 absolute top-0 left-0 h-full"
              :class="[
                ownerCurrentStep === 0 ? 'opacity-100 translate-x-0' : 'opacity-0 translate-x-full pointer-events-none'
              ]">
              <div class="bg-white rounded-2xl p-8 shadow-lg border border-gray-100 h-full">
                <div class="grid md:grid-cols-2 gap-6 h-full">
                  <div class="flex flex-col">
                    <div class="flex items-center gap-4 mb-6">
                      <div class="w-12 h-12 bg-indigo-100 rounded-full flex items-center justify-center text-indigo-600 font-bold text-xl">
                        1
                      </div>
                      <h3 class="text-2xl font-bold text-gray-900">Crea tu contrato y comparte el enlace</h3>
                    </div>
                    <div class="space-y-4 text-gray-600">
                      <p>Crea un nuevo contrato para tu propiedad tal y como lo harías en Idealista, Fotocasa y otras plataformas.</p>
                      <p>Accede a tu contrato y comparte el enlace en las plataformas donde lo tengas anunciado, de esta forma lograrás que todos los visitantes se apunten automáticamente como interesados y puedan empezar a hacer ofertas por tu propiedad.</p>
                    </div>
                  </div>
                  <div class="bg-white rounded-xl shadow-sm p-6 border border-gray-100 h-fit">
                    <div class="relative h-48 rounded-lg bg-gray-200 mb-4 overflow-hidden">
                      <img src="https://images.unsplash.com/photo-1560448204-e02f11c3d0e2?ixlib=rb-4.0.3&auto=format&fit=crop&w=800&q=80" class="w-full h-full object-cover" alt="Property" />
                      <div class="absolute bottom-3 right-3 bg-white shadow-md px-3 py-1.5 rounded-lg">
                        <p class="text-base font-bold text-gray-900">380.000€</p>
                      </div>
                    </div>
                    <h3 class="text-lg font-bold text-gray-800 mb-3">Vivienda de lujo en el Calle Pacífico</h3>
                    <div class="flex items-center text-gray-600 text-sm mb-2">
                      <svg class="h-5 w-5 text-indigo-500 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                      </svg>
                      Málaga
                    </div>
                    <div class="flex gap-4 text-sm text-gray-600 mb-4">
                      <span>3 habitaciones</span>
                      <span>2 baños</span>
                      <span>150 m²</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Step 2: Assign Candidate -->
            <div class="w-full transition-all duration-300 absolute top-0 left-0 h-full"
              :class="[
                ownerCurrentStep === 1 ? 'opacity-100 translate-x-0' : 'opacity-0 translate-x-full pointer-events-none'
              ]">
              <div class="bg-white rounded-2xl p-8 shadow-lg border border-gray-100 h-full">
                <div class="grid md:grid-cols-2 gap-6 h-full">
                  <div class="flex flex-col">
                    <div class="flex items-center gap-4 mb-6">
                      <div class="w-12 h-12 bg-indigo-100 rounded-full flex items-center justify-center text-indigo-600 font-bold text-xl">
                        2
                      </div>
                      <h3 class="text-2xl font-bold text-gray-900">Recibe ofertas y asigna al mejor candidato</h3>
                    </div>
                    <div class="space-y-4 text-gray-600">
                      <p>Al publicar tu contrato, empezarás a recibir ofertas de los interesados. Asigna al candidato que te haga la mejor oferta o que tenga el mejor perfil.</p>
                    </div>
                  </div>
                  <div class="bg-white rounded-xl shadow-sm p-6 border border-gray-100 h-fit">
                    <!-- Interested Applicants Example -->
                    <div class="space-y-4">
                      <div class="flex justify-between items-center pb-2 border-b border-gray-100">
                        <div class="flex items-center">
                          <div class="h-8 w-8 rounded-full bg-gradient-to-br from-indigo-500 to-purple-600 flex items-center justify-center text-white font-bold mr-3">
                            E
                          </div>
                          <p class="font-bold text-gray-900">Ramón Estrada</p>
                        </div>
                        <button class="px-4 py-1.5 bg-gradient-to-r from-indigo-500 to-purple-600 text-white text-sm font-semibold rounded-md">
                          Asignar
                        </button>
                      </div>
                      <div class="text-sm text-gray-600">
                        <p><span class="font-semibold">Oferta:</span> 395.000€</p>
                        <p><span class="font-semibold">Ingresos mensuales:</span> 2300€</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Step 3: Sign Contract -->
            <div class="w-full transition-all duration-300 absolute top-0 left-0 h-full"
              :class="[
                ownerCurrentStep === 2 ? 'opacity-100 translate-x-0' : 'opacity-0 translate-x-full pointer-events-none'
              ]">
              <div class="bg-white rounded-2xl p-8 shadow-lg border border-gray-100 h-full">
                <div class="grid md:grid-cols-2 gap-6 h-full">
                  <div class="flex flex-col">
                    <div class="flex items-center gap-4 mb-6">
                      <div class="w-12 h-12 bg-indigo-100 rounded-full flex items-center justify-center text-indigo-600 font-bold text-xl">
                        3
                      </div>
                      <h3 class="text-2xl font-bold text-gray-900">Firma el contrato</h3>
                    </div>
                    <div class="space-y-4 text-gray-600">
                      <p>Revisa tus datos personales y el contrato generado para tu caso específico. Revisa también la documentación subida por el candidato asignado, si los dos estais de acuerdo, ¡ya podeis firmar!</p>
                    </div>
                  </div>
                  <div class="bg-white rounded-xl shadow-sm p-6 border border-gray-100 h-fit">
                    <!-- Sign Contract Example -->
                    <div class="text-center">
                      <div class="text-indigo-600 mb-4">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                        </svg>
                      </div>
                      <h3 class="text-lg font-bold text-gray-900 mb-2">Firmar contrato</h3>
                      <p class="text-sm text-gray-600 mb-4">Firma en el espacio indicado:</p>
                      <div class="border-2 border-dashed border-gray-300 rounded-lg h-32 mb-4"></div>
                      <button class="w-full px-4 py-2 bg-gradient-to-r from-purple-600 to-indigo-600 text-white font-medium rounded-md">
                        Firmar contrato
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Step 4: Payment and Download -->
            <div class="w-full transition-all duration-300 absolute top-0 left-0 h-full"
              :class="[
                ownerCurrentStep === 3 ? 'opacity-100 translate-x-0' : 'opacity-0 translate-x-full pointer-events-none'
              ]">
              <div class="bg-white rounded-2xl p-8 shadow-lg border border-gray-100 h-full">
                <div class="grid md:grid-cols-2 gap-6 h-full">
                  <div class="flex flex-col">
                    <div class="flex items-center gap-4 mb-6">
                      <div class="w-12 h-12 bg-indigo-100 rounded-full flex items-center justify-center text-indigo-600 font-bold text-xl">
                        4
                      </div>
                      <h3 class="text-2xl font-bold text-gray-900">Finaliza el proceso</h3>
                    </div>
                    <div class="space-y-4 text-gray-600">
                      <p>Cuando ambas partes firmen, ya puedes abonar la tarifa de compromiso, es un pago totalmente seguro que te asegura la veracidad y formalización del contrato. No te preocupes, si la otra parte se echa atrás, se te reembolsa el dinero.</p>
                      <p>Descarga tu contrato, podrás comprobar que todos los detalles y cláusulas están incluidas y que se encuentra firmado por ambas partes. ¡Enhorabuena!</p>
                    </div>
                  </div>
                  <div class="bg-white rounded-xl shadow-sm p-6 border border-gray-100 h-fit">
                    <!-- Payment Button Example -->
                    <div class="text-center">
                      <div class="text-indigo-600 mb-4">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12 mx-auto" fill="currentColor" viewBox="0 0 256 256">
                          <path d="M224,48H32A16,16,0,0,0,16,64V192a16,16,0,0,0,16,16H224a16,16,0,0,0,16-16V64A16,16,0,0,0,224,48ZM32,64H224V80H32ZM224,192H32V96H224v96ZM64,160a8,8,0,0,1,8-8H136a8,8,0,0,1,0,16H72A8,8,0,0,1,64,160Zm144,0a8,8,0,0,1-8,8H184a8,8,0,0,1,0-16h16A8,8,0,0,1,208,160Z"></path>
                        </svg>
                      </div>
                      <button class="w-full px-4 py-3 bg-gradient-to-r from-purple-600 to-indigo-600 text-white font-medium rounded-md mb-4">
                        Abonar tarifa de compromiso
                      </button>
                      <button class="w-full px-4 py-3 border border-indigo-600 text-indigo-600 font-medium rounded-md hover:bg-indigo-50">
                        Descargar contrato firmado
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Navigation Buttons -->
        <div class="flex justify-between items-center mt-8">
          <button @click="prevStep('owner')" class="p-2 rounded-full bg-white shadow-md hover:bg-gray-50 transition-colors">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
            </svg>
          </button>
          <div class="flex space-x-2">
            <button v-for="step in ownerTotalSteps" :key="step" @click="goToStep('owner', step - 1)"
              :class="[
                'w-3 h-3 rounded-full transition-all duration-200',
                ownerCurrentStep === step - 1
                  ? 'bg-indigo-600'
                  : 'bg-gray-300 hover:bg-gray-400'
              ]"
            ></button>
          </div>
          <button @click="nextStep('owner')" class="p-2 rounded-full bg-white shadow-md hover:bg-gray-50 transition-colors">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
            </svg>
          </button>
        </div>
      </div>

      <!-- Applicant Steps -->
      <div v-show="activeGuideType === 'applicant'" class="relative">
        <!-- Carousel Container -->
        <div class="overflow-hidden">
          <div class="relative w-full" style="min-height: 420px">
            <!-- Step 1: Find Property -->
            <div class="w-full transition-all duration-300 absolute top-0 left-0 h-full"
              :class="[
                applicantCurrentStep === 0 ? 'opacity-100 translate-x-0' : 'opacity-0 translate-x-full pointer-events-none'
              ]">
              <div class="bg-white rounded-2xl p-8 shadow-lg border border-gray-100 h-full">
                <div class="grid md:grid-cols-2 gap-6 h-full">
                  <div class="flex flex-col">
                    <div class="flex items-center gap-4 mb-6">
                      <div class="w-12 h-12 bg-indigo-100 rounded-full flex items-center justify-center text-indigo-600 font-bold text-xl">
                        1
                      </div>
                      <h3 class="text-2xl font-bold text-gray-900">Encuentra tu propiedad ideal</h3>
                    </div>
                    <div class="space-y-4 text-gray-600">
                      <p>Pega el enlace de la propiedad en el buscador y muestra tu interés.</p>
                    </div>
                  </div>
                  <div class="bg-white rounded-xl shadow-sm p-6 border border-gray-100 h-fit">
                    <div class="relative h-48 rounded-lg bg-gray-200 mb-4 overflow-hidden">
                      <img src="https://images.unsplash.com/photo-1560448204-e02f11c3d0e2?ixlib=rb-4.0.3&auto=format&fit=crop&w=800&q=80" class="w-full h-full object-cover" alt="Property" />
                      <div class="absolute bottom-3 right-3 bg-white shadow-md px-3 py-1.5 rounded-lg">
                        <p class="text-base font-bold text-gray-900">380.000€</p>
                      </div>
                    </div>
                    <h3 class="text-lg font-bold text-gray-800 mb-3">Vivienda de lujo en el Calle Pacífico</h3>
                    <div class="flex items-center text-gray-600 text-sm mb-2">
                      <svg class="h-5 w-5 text-indigo-500 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                      </svg>
                      Málaga
                    </div>
                    <div class="flex gap-4 text-sm text-gray-600 mb-4">
                      <span>3 habitaciones</span>
                      <span>2 baños</span>
                      <span>150 m²</span>
                    </div>
                    <button class="w-full px-4 py-2 bg-gradient-to-r from-purple-600 to-indigo-600 text-white font-medium rounded-md">
                      Me interesa
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <!-- Step 2: Make Offer -->
            <div class="w-full transition-all duration-300 absolute top-0 left-0 h-full"
              :class="[
                applicantCurrentStep === 1 ? 'opacity-100 translate-x-0' : 'opacity-0 translate-x-full pointer-events-none'
              ]">
              <div class="bg-white rounded-2xl p-8 shadow-lg border border-gray-100 h-full">
                <div class="grid md:grid-cols-2 gap-6 h-full">
                  <div class="flex flex-col">
                    <div class="flex items-center gap-4 mb-6">
                      <div class="w-12 h-12 bg-indigo-100 rounded-full flex items-center justify-center text-indigo-600 font-bold text-xl">
                        2
                      </div>
                      <h3 class="text-2xl font-bold text-gray-900">Haz tu mejor oferta</h3>
                    </div>
                    <div class="space-y-4 text-gray-600">
                      <p>Destaca entre otros interesados haciendo una oferta competitiva y completando tu perfil con información relevante.</p>
                    </div>
                  </div>
                  <div class="bg-white rounded-xl shadow-sm p-6 border border-gray-100 h-fit">
                    <!-- Offer Form Example -->
                    <div class="space-y-4">
                      <h3 class="text-lg font-bold text-gray-900 mb-4">Tu oferta</h3>
                      <div>
                        <label class="block text-sm font-medium text-gray-700 mb-1">Cantidad ofertada</label>
                        <input type="text" value="395.000€" class="w-full px-3 py-2 border border-gray-300 rounded-md" readonly />
                      </div>
                      <div>
                        <label class="block text-sm font-medium text-gray-700 mb-1">Ingresos mensuales</label>
                        <input type="text" value="2.300€" class="w-full px-3 py-2 border border-gray-300 rounded-md" readonly />
                      </div>
                      <button class="w-full px-4 py-2 bg-gradient-to-r from-purple-600 to-indigo-600 text-white font-medium rounded-md">
                        Enviar oferta
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Step 3: Sign Contract -->
            <div class="w-full transition-all duration-300 absolute top-0 left-0 h-full"
              :class="[
                applicantCurrentStep === 2 ? 'opacity-100 translate-x-0' : 'opacity-0 translate-x-full pointer-events-none'
              ]">
              <div class="bg-white rounded-2xl p-8 shadow-lg border border-gray-100 h-full">
                <div class="grid md:grid-cols-2 gap-6 h-full">
                  <div class="flex flex-col">
                    <div class="flex items-center gap-4 mb-6">
                      <div class="w-12 h-12 bg-indigo-100 rounded-full flex items-center justify-center text-indigo-600 font-bold text-xl">
                        3
                      </div>
                      <h3 class="text-2xl font-bold text-gray-900">Firma el contrato</h3>
                    </div>
                    <div class="space-y-4 text-gray-600">
                      <p>Si el propietario te selecciona, revisa tus datos personales y el contrato generado para tu caso específico. Sube la documentación necesaria y espera al visto bueno por el propietario. Si los dos estais de acuerdo, ¡ya podeis firmar!</p>
                    </div>
                  </div>
                  <div class="bg-white rounded-xl shadow-sm p-6 border border-gray-100 h-fit">
                    <!-- Sign Contract Example -->
                    <div class="text-center">
                      <div class="text-indigo-600 mb-4">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                        </svg>
                      </div>
                      <h3 class="text-lg font-bold text-gray-900 mb-2">Firmar contrato</h3>
                      <p class="text-sm text-gray-600 mb-4">Firma en el espacio indicado:</p>
                      <div class="border-2 border-dashed border-gray-300 rounded-lg h-32 mb-4"></div>
                      <button class="w-full px-4 py-2 bg-gradient-to-r from-purple-600 to-indigo-600 text-white font-medium rounded-md">
                        Firmar contrato
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Step 4: Payment and Move -->
            <div class="w-full transition-all duration-300 absolute top-0 left-0 h-full"
              :class="[
                applicantCurrentStep === 3 ? 'opacity-100 translate-x-0' : 'opacity-0 translate-x-full pointer-events-none'
              ]">
              <div class="bg-white rounded-2xl p-8 shadow-lg border border-gray-100 h-full">
                <div class="grid md:grid-cols-2 gap-6 h-full">
                  <div class="flex flex-col">
                    <div class="flex items-center gap-4 mb-6">
                      <div class="w-12 h-12 bg-indigo-100 rounded-full flex items-center justify-center text-indigo-600 font-bold text-xl">
                        4
                      </div>
                      <h3 class="text-2xl font-bold text-gray-900">Finaliza el proceso</h3>
                    </div>
                    <div class="space-y-4 text-gray-600">
                      <p>Cuando ambas partes firmen, ya puedes abonar la tarifa de compromiso, es un pago totalmente seguro que te asegura la veracidad y formalización del contrato. No te preocupes, si la otra parte se echa atrás, se te reembolsa el dinero.</p>
                      <p>Descarga tu contrato, podrás comprobar que todos los detalles y cláusulas están incluidas y que se encuentra firmado por ambas partes. ¡Enhorabuena!</p>
                    </div>
                  </div>
                  <div class="bg-white rounded-xl shadow-sm p-6 border border-gray-100 h-fit">
                    <!-- Payment Button Example -->
                    <div class="text-center">
                      <div class="text-indigo-600 mb-4">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12 mx-auto" fill="currentColor" viewBox="0 0 256 256">
                          <path d="M224,48H32A16,16,0,0,0,16,64V192a16,16,0,0,0,16,16H224a16,16,0,0,0,16-16V64A16,16,0,0,0,224,48ZM32,64H224V80H32ZM224,192H32V96H224v96ZM64,160a8,8,0,0,1,8-8H136a8,8,0,0,1,0,16H72A8,8,0,0,1,64,160Zm144,0a8,8,0,0,1-8,8H184a8,8,0,0,1,0-16h16A8,8,0,0,1,208,160Z"></path>
                        </svg>
                      </div>
                      <button class="w-full px-4 py-3 bg-gradient-to-r from-purple-600 to-indigo-600 text-white font-medium rounded-md mb-4">
                        Abonar tarifa de compromiso
                      </button>
                      <button class="w-full px-4 py-3 border border-indigo-600 text-indigo-600 font-medium rounded-md hover:bg-indigo-50">
                        Descargar contrato firmado
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Navigation Buttons -->
        <div class="flex justify-between items-center mt-8">
          <button @click="prevStep('applicant')" class="p-2 rounded-full bg-white shadow-md hover:bg-gray-50 transition-colors">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
            </svg>
          </button>
          <div class="flex space-x-2">
            <button v-for="step in applicantTotalSteps" :key="step" @click="goToStep('applicant', step - 1)"
              :class="[
                'w-3 h-3 rounded-full transition-all duration-200',
                applicantCurrentStep === step - 1
                  ? 'bg-indigo-600'
                  : 'bg-gray-300 hover:bg-gray-400'
              ]"
            ></button>
          </div>
          <button @click="nextStep('applicant')" class="p-2 rounded-full bg-white shadow-md hover:bg-gray-50 transition-colors">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref } from 'vue'

const activeGuideType = ref('owner')
const ownerCurrentStep = ref(0)
const applicantCurrentStep = ref(0)

const ownerTotalSteps = 4
const applicantTotalSteps = 4

function nextStep(type) {
  if (type === 'owner') {
    ownerCurrentStep.value = (ownerCurrentStep.value + 1) % ownerTotalSteps
  } else {
    applicantCurrentStep.value = (applicantCurrentStep.value + 1) % applicantTotalSteps
  }
}

function prevStep(type) {
  if (type === 'owner') {
    ownerCurrentStep.value = ownerCurrentStep.value === 0 ? ownerTotalSteps - 1 : ownerCurrentStep.value - 1
  } else {
    applicantCurrentStep.value = applicantCurrentStep.value === 0 ? applicantTotalSteps - 1 : applicantCurrentStep.value - 1
  }
}

function goToStep(type, step) {
  if (type === 'owner') {
    ownerCurrentStep.value = step
  } else {
    applicantCurrentStep.value = step
  }
}
</script>

<style scoped>
.overflow-hidden {
  transition: height 0.3s ease-in-out;
}
</style> 