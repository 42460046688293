<template>
  <div class="min-h-screen bg-slate-50">
    <!-- Hero Section -->
    <section class="relative overflow-hidden bg-gradient-to-br from-indigo-900 via-purple-800 to-indigo-700 text-white">
      <!-- Decorative elements -->
      <div class="absolute inset-0 opacity-20">
        <div
          class="absolute top-0 right-0 w-80 h-80 bg-gradient-to-br from-pink-400 to-purple-500 rounded-full filter blur-3xl transform translate-x-1/3 -translate-y-1/3 opacity-30">
        </div>
        <div
          class="absolute bottom-0 left-0 w-80 h-80 bg-gradient-to-br from-blue-400 to-cyan-300 rounded-full filter blur-3xl transform -translate-x-1/3 translate-y-1/3 opacity-30">
        </div>
      </div>

      <!-- Icon Pattern Background -->
      <div class="absolute inset-0 opacity-[0.03]">
        <div class="absolute inset-0 grid grid-cols-6 gap-8 p-8">
          <div v-for="i in 48" :key="i" class="flex items-center justify-center">
            <HomeIcon v-if="i % 6 === 0" class="w-8 h-8" />
            <DocumentTextIcon v-else-if="i % 6 === 1" class="w-8 h-8" />
            <ShieldCheckIcon v-else-if="i % 6 === 2" class="w-8 h-8" />
            <PencilSquareIcon v-else-if="i % 6 === 3" class="w-8 h-8" />
            <DocumentArrowUpIcon v-else-if="i % 6 === 4" class="w-8 h-8" />
            <LockClosedIcon v-else class="w-8 h-8" />
          </div>
        </div>
      </div>

      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="py-20 md:py-28 relative z-10">
          <div class="text-center max-w-4xl mx-auto space-y-8">
            <div class="flex flex-col items-center">
              <h1 class="text-4xl sm:text-5xl lg:text-6xl font-bold">
                <span>Contratos inteligentes para tus transacciones inmobiliarias</span>
              </h1>

              <!-- CTA Buttons -->
              <div class="mt-12 flex justify-center">
                <div class="inline-flex rounded-lg">
                  <button @click="showOwnerText" :class="[
                    'px-8 py-3 text-lg font-medium transition-all duration-200 relative',
                    showingOwnerText
                      ? 'text-white after:absolute after:bottom-0 after:left-0 after:right-0 after:h-0.5 after:bg-white'
                      : 'text-white/80 hover:text-white'
                  ]">
                    Soy propietario
                  </button>
                  <button @click="showApplicantText" :class="[
                    'px-8 py-3 text-lg font-medium transition-all duration-200 relative',
                    showingApplicantText
                      ? 'text-white after:absolute after:bottom-0 after:left-0 after:right-0 after:h-0.5 after:bg-white'
                      : 'text-white/80 hover:text-white'
                  ]">
                    Busco propiedad
                  </button>
                </div>
              </div>

              <p v-if="showingOwnerText" class="mt-6 text-xl lg:text-2xl text-white-500 text-center max-w-3xl">
                Publica tu anuncio, selecciona candidatos y firma contratos digitales.
              </p>
              <p v-if="showingApplicantText" class="mt-6 text-xl lg:text-2xl text-white-500 text-center max-w-3xl">
                Encuentra tu propiedad ideal, destaca con tu oferta y firma online.
              </p>
              <p class="mt-3 text-xl lg:text-2xl text-white-700 text-center max-w-3xl">
                Sin complicaciones, sin comisiones.
              </p>

              <!-- Features Grid -->
              <div class="grid grid-cols-1 md:grid-cols-3 gap-6 mt-24 max-w-3xl mx-auto">
                <div class="flex items-center space-x-3 justify-center">
                  <HomeIcon class="h-6 w-6 text-pink-200" />
                  <span class="text-indigo-100">Anuncios detallados</span>
                </div>
                <div class="flex items-center space-x-3 justify-center">
                  <ShieldCheckIcon class="h-6 w-6 text-pink-200" />
                  <span class="text-indigo-100">Verificación de usuarios</span>
                </div>
                <div class="flex items-center space-x-3 justify-center">
                  <DocumentTextIcon class="h-6 w-6 text-pink-200" />
                  <span class="text-indigo-100">Contratos dinámicos</span>
                </div>
                <div class="flex items-center space-x-3 justify-center">
                  <PencilSquareIcon class="h-6 w-6 text-pink-200" />
                  <span class="text-indigo-100">Firmas digitales</span>
                </div>
                <div class="flex items-center space-x-3 justify-center">
                  <DocumentArrowUpIcon class="h-6 w-6 text-pink-200" />
                  <span class="text-indigo-100">Gestión de documentos</span>
                </div>
                <div class="flex items-center space-x-3 justify-center">
                  <LockClosedIcon class="h-6 w-6 text-pink-200" />
                  <span class="text-indigo-100">Pagos seguros</span>
                </div>
              </div>

              <!-- CTA Button -->
              <div class="mt-12 flex justify-center">
                <router-link to="/signup"
                  class="inline-flex items-center justify-center px-8 py-4 border border-white text-lg font-medium rounded-lg text-white hover:bg-white/10 transition-all duration-200">
                  Empieza ahora gratis
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- PAS Section -->
    <PasSection />

    <!-- Comparison Table -->
    <ComparisonTable />

    <!-- Step by Step Guide Section -->
    <section id="step-by-step-guide">
      <div class="hidden md:block">
        <StepByStepGuide />
      </div>
      <div class="block md:hidden">
        <StepByStepGuideMobile />
      </div>
    </section>

    <!-- Features Grid -->
    <FeaturesGrid />

    <!-- Testimonials Section -->
    <TestimonialsSection />

    <!-- Tarifa de Compromiso Section -->
    <CommitmentFee />

    <!-- FAQ section -->
    <FrequentlyAskedQuestions />

    <!-- CTA Section - Call Request -->
    <CallRequestSection />



  </div>
</template>

<script setup>
import { ref } from 'vue'
import { ChevronDownIcon, ArrowRightIcon, ShieldCheckIcon, LockClosedIcon, ClockIcon, DocumentTextIcon, HomeIcon, BanknotesIcon, TrophyIcon, DocumentArrowUpIcon, PencilSquareIcon, CheckIcon, XMarkIcon, MinusIcon } from '@heroicons/vue/24/solid'
import StepByStepGuide from './StepByStepGuide.vue'
import StepByStepGuideMobile from './StepByStepGuideMobile.vue'
import StartNowButton from './StartNowButton.vue'
import FrequentlyAskedQuestions from './FrequentlyAskedQuestions.vue'
import PasSection from './PasSection.vue'
import ComparisonTable from './ComparisonTable.vue'
import FeaturesGrid from './FeaturesGrid.vue'
import CommitmentFee from './CommitmentFee.vue'
import CallRequestSection from './CallRequestSection.vue'
import TestimonialsSection from './TestimonialsSection.vue'

const showingOwnerText = ref(true)
const showingApplicantText = ref(false)
const activeGuideType = ref('owner')

function showOwnerText() {
  showingOwnerText.value = true
  showingApplicantText.value = false
}

function showApplicantText() {
  showingOwnerText.value = false
  showingApplicantText.value = true
}

const activeUserType = ref('owner')

const userTypes = [
  { id: 'owner', label: 'Para propietarios' },
  { id: 'applicant', label: 'Para interesados' }
]

const ownerSteps = [
  {
    title: 'Crea tu cuenta de propietario',
    description: 'Regístrate gratuitamente para comenzar a publicar propiedades.'
  },
  {
    title: 'Publica tus anuncios',
    description: 'Sube fotos, añade descripciones detalladas y establece tus condiciones de venta o alquiler.'
  },
  {
    title: 'Gestiona ofertas y firma',
    description: 'Recibe ofertas, verifica a los candidatos y firma contratos digitalmente de forma segura.'
  }
]

const applicantSteps = [
  {
    title: 'Crea tu cuenta de interesado',
    description: 'Regístrate gratuitamente para comenzar a visitar anuncios y poder aplicar a propiedades.'
  },
  {
    title: 'Encuentra tu propiedad ideal',
    description: 'Consulta tu catálogo de anuncios en los que estás interesado y encuentra el que mejor se ajuste a ti.'
  },
  {
    title: 'Aplica y firma',
    description: 'Envía tu oferta, verifica tu información y firma el contrato digital cuando seas seleccionado.'
  }
]

const faqs = ref([
{
    question: "¿Cuánto cuesta el servicio?",
    answer: "El registro es gratuito. La plataforma aplica una cantidad fija en concepto de tarifa de compromiso por un valor de 50€ para poder dar por completada una operación. Sin costes ocultos, sin suscripciones, sin comisiones. La tarifa de compromiso garantiza que ambas partes cumplan lo acordado.",
    isOpen: false
  },
  {
    question: "¿Cómo funciona el sistema de pujas?",
    answer: "Nuestro sistema de pujas permite que los interesados en una propiedad compitan de forma transparente. Los candidatos presentan sus ofertas, y estas se ordenan automáticamente según criterios como el precio ofertado y las condiciones propuestas. El propietario puede ver todas las ofertas organizadas, filtrando fácilmente a los candidatos más serios. Esto garantiza un proceso justo donde ganan las mejores ofertas, no los intermediarios.",
    isOpen: false
  },
  {
    question: "¿Qué ventajas ofrece notarial frente a las inmobiliarias tradicionales?",
    answer: "A diferencia de las inmobiliarias, notarial no cobra comisiones porcentuales (que suelen ser del 3-7% del valor total). Ofrecemos seguridad jurídica equivalente, sistemas de pago seguros y verificación de usuarios, pero con control total del proceso para el propietario y mayor rapidez en las transacciones, ahorrándote miles de euros en comisiones innecesarias.",
    isOpen: false
  },
  {
    question: "¿Qué ventajas tiene notarial sobre los portales inmobiliarios?",
    answer: "Los portales inmobiliarios solo conectan oferta y demanda, pero no ofrecen seguridad jurídica, verificación real de candidatos, protección de pagos ni contratos digitales verificados. Con notarial obtienes un sistema completo que va desde la publicación hasta la firma digital de contratos legalmente vinculantes, además de un filtro efectivo de candidatos serios gracias al sistema de pujas y la tarifa de compromiso.",
    isOpen: false
  },
  {
    question: "¿Por qué usar notarial en lugar del trato directo entre particulares?",
    answer: "El trato directo entre particulares carece de seguridad jurídica, verificación de identidad, protección de pagos y filtrado de candidatos serios. Notarial te ofrece todas estas ventajas manteniendo el control total del proceso y sin comisiones porcentuales, proporcionando la tranquilidad y seguridad que el trato directo no puede garantizar.",
    isOpen: false
  },
  {
    question: "¿Cómo verifican a los inquilinos y propietarios?",
    answer: "Realizamos verificaciones automáticas de identidad y documentos para garantizar la seguridad. Utilizamos tecnología avanzada para validar la autenticidad de los documentos subidos y verificar la identidad en tiempo real.",
    isOpen: false
  },
  {
    question: "¿Qué documentos necesito para empezar?",
    answer: "Tanto si eres el propietario de un inmueble como si estás buscando el tuyo, tan solo necesitas tener preparado un documento que verifique tu identidad para poder garantizar la seguridad de todas las partes. Según el tipo de perfil que tengas, te guiaremos paso a paso en el proceso con el resto de documentos que necesites.",
    isOpen: false
  },
  {
    question: "¿Son seguros mis datos personales y bancarios?",
    answer: "Absolutamente. Cumplimos con la normativa RGPD y utilizamos encriptación para proteger todos tus datos. Nunca compartimos tu información con terceros sin tu consentimiento explícito.",
    isOpen: false
  },
  {
    question: "¿Es segura la firma digital?",
    answer: "Sí, utilizamos un sistema de firma digital que cumple y garantiza la máxima seguridad. Nuestras firmas tienen la misma validez legal que las manuscritas.",
    isOpen: false
  },
  {
    question: "¿Qué garantías tengo al usar la plataforma?",
    answer: "Ofrecemos verificación de identidad, contratos legales, sistema seguro de pagos y soporte especializado durante todo el proceso para casos individuales. Además, la tarifa de compromiso protege a ambas partes contra cancelaciones injustificadas.",
    isOpen: false
  },

  {
    question: "¿Qué soporte ofrecen durante el contrato?",
    answer: "Contamos con un equipo especializado disponible para resolver cualquier incidencia contemplada en nuestro contrato.",
    isOpen: false
  },
 
  {
    question: "¿Cómo funciona el sistema de pagos?",
    answer: "Utilizamos pasarelas de pago seguras como Stripe que cumplen con los estándares PCI DSS. Los pagos se procesan de forma encriptada y nunca almacenamos los datos completos de tu tarjeta en nuestros servidores.",
    isOpen: false
  },
  {
    question: "¿Qué validez legal tienen los contratos generados?",
    answer: "Nuestros contratos son redactados y adaptados dinámicamente a la normativa vigente. Tienen plena validez legal y son admitidos en cualquier juzgado o procedimiento oficial.",
    isOpen: false
  },
  {
    question: "¿Qué ocurre si hay un conflicto entre las partes?",
    answer: "En caso de desacuerdo previo al pago de la tarifa de compromiso por ambas partes, el sistema de tarifa de compromiso actúa como mecanismo de protección, la parte perjudicada puede solicitar el reembolso de la tarifa de compromiso y el proceso se dará por cerrado.",
    isOpen: false
  }
])

function toggleFaq(index) {
  faqs.value = faqs.value.map((faq, i) => ({
    ...faq,
    isOpen: i === index ? !faq.isOpen : false
  }))
}
</script>

<style scoped>
.animate-float {
  animation: float 3s ease-in-out infinite;
}

@keyframes float {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(0px);
  }
}

.group:hover h3 {
  color: rgb(37, 99, 235);
}

/* Smooth height animation for FAQ answers */
.overflow-hidden {
  transition: height 0.3s ease-in-out;
}
</style>

<script>
export default {
  name: 'LandingPage'
}
</script>